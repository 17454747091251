const prefix = (type) => `eureka/kid/${type}`;

export const SET_CURRENT_KID_START = prefix('SET_CURRENT_KID_START');
export const SET_CURRENT_KID_SUCCESS = prefix('SET_CURRENT_KID_SUCCESS');
export const SET_CURRENT_KID_FAILED = prefix('SET_CURRENT_KID_FAILED');

export const LOAD_KIDS_START = prefix('LOAD_KIDS_START');
export const LOAD_KIDS_SUCCESS = prefix('LOAD_KIDS_SUCCESS');
export const LOAD_KIDS_FAILED = prefix('LOAD_KIDS_FAILED');

export const DELETE_LICENSE_START = prefix('DELETE_LICENSE_START');
export const DELETE_LICENSE_SUCCESS = prefix('DELETE_LICENSE_SUCCESS');
export const DELETE_LICENSE_FAILED = prefix('DELETE_LICENSE_FAILED');

export const CREATE_LICENSE_START = prefix('CREATE_LICENSE_START');
export const CREATE_LICENSE_SUCCESS = prefix('CREATE_LICENSE_SUCCESS');
export const CREATE_LICENSE_FAILED = prefix('CREATE_LICENSE_FAILED');

export const FETCH_KID_START = prefix('FETCH_KID_START');
export const FETCH_KID_SUCCESS = prefix('FETCH_KID_SUCCESS');
export const FETCH_KID_FAILED = prefix('FETCH_KID_FAILED');

export const FETCH_KID_REPORT_START = prefix('FETCH_KID_REPORT_START');
export const FETCH_KID_REPORT_SUCCESS = prefix('FETCH_KID_REPORT_SUCCESS');
export const FETCH_KID_REPORT_FAILED = prefix('FETCH_KID_REPORT_FAILED');

export const FETCH_PROGRESSES_START = prefix('FETCH_PROGRESSES_START');
export const FETCH_PROGRESSES_SUCCESS = prefix('FETCH_PROGRESSES_SUCCESS');
export const FETCH_PROGRESSES_FAILED = prefix('FETCH_PROGRESSES_FAILED');

export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');

export const CLEAR_PROGRESS = prefix('CLEAR_PROGRESS');
