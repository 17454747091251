/* eslint-disable no-continue */
/**
 * Adds space every 3 characters in a number
 * @example - 123456789 => 123 456 789
 * @param number {string} - the number to be formatted
 */
export const addSpacesBetweenDigits = (number: string) => {
  // Regex to match every 3 digits
  const searchRegExp = new RegExp(/\B(?=(\d{3})+(?!\d))/g);
  return number.replace(searchRegExp, ' ');
};

const BRACKETS = [
  ['(', ')'],
  ['[', ']'],
  ['{', '}'],
];

export const areBracketsBalanced = (expression: string) => {
  /**
   * Additional check for string as still the majority of the code is written in JS
   */
  if (typeof expression !== 'string' || expression.length <= 1) return false;

  const stack: string[] = [];
  for (let i = 0; i < expression.length; i++) {
    const char = expression[i];
    const isOpeningBracket = BRACKETS.some((pair) => pair[0] === char);
    if (isOpeningBracket) {
      stack.push(char);
      continue;
    }

    const isClosingBracket = BRACKETS.some((pair) => pair[1] === char);
    if (isClosingBracket) {
      const last = stack.pop();
      const areBracketsMatching = BRACKETS.find((pair) => pair[0] === last && pair[1] === char);
      if (!last || !areBracketsMatching) {
        return false;
      }
    }
  }
  return stack.length === 0;
};

type FormatNumbersOptions = {
  // between every 3 characters
  addSpaces?: boolean;
};

export const formatNumbers = (value: string, options?: FormatNumbersOptions) => {
  if (!value) return value;

  let expression = value.trim();

  // matches integers and decimals
  const numberRegex = new RegExp(/[\d|,|.]+/g);
  const matched = expression.match(numberRegex);
  if (!Array.isArray(matched)) return expression;

  matched.forEach((item) => {
    const normalizedValue = item.replace(',', '.');
    if (!Number.isNaN(Number(normalizedValue))) {
      const newNumber = options?.addSpaces ? addSpacesBetweenDigits(normalizedValue) : normalizedValue;
      const normalizedNewNumber = newNumber.replace('.', ',');

      expression = expression.replace(item, normalizedNewNumber);
    }
  });
  return expression;
};

export const isStringRepresentingNumber = (() => {
  /**
   * After casting to number:
   * - whitespace => 0
   * - empty string => 0
   *
   * So, it will return true despite the fact that the string is not a number, so we need to avoid it
   */
  const EXCEPTIONS = [' ', ''];
  return (value: string) => {
    if (EXCEPTIONS.includes(value)) return false;
    const normalizedValue = value.replace(',', '.');
    return !Number.isNaN(Number(normalizedValue));
  };
})();

export const replaceCharacterBetweenNumbers = (value: string, searchChar: string, replaceChar: string): string => {
  if (typeof value !== 'string') return '';
  if (typeof searchChar !== 'string' || typeof replaceChar !== 'string') return value;
  /**
   * It a bit more complex than just `string.replace(',', '.')`,
   * because we need to be sure that we perform this action only between numbers
   */
  return value
    .split('')
    .map((char, index) => {
      const isInRange = index > 0 && index < value.length - 1;

      if (!isInRange) return char;

      const isPreviousCharacterNumber = isStringRepresentingNumber(value[index - 1]);
      const isNextCharacterNumber = isStringRepresentingNumber(value[index + 1]);
      return char === searchChar && isPreviousCharacterNumber && isNextCharacterNumber ? replaceChar : char;
    })
    .join('');
};

/**
 * The function is used to replace ',' (comma) with '.' (dot) in decimal numbers that may occur in the string.
 * The reason why it's needed as most of the countries use comma to divide the whole and the decimal part of the number.
 * However, the programming language only supports dot as a divider
 *
 * @example "3,5 + 12,5" --> "3.5 + 12.5"
 *
 * @param {string} value
 */
export const replaceCommaByDotInNumbers = (value: string): string => replaceCharacterBetweenNumbers(value, ',', '.');

export const replaceDotByCommaInNumbers = (value: string): string => replaceCharacterBetweenNumbers(value, '.', ',');

/**
 * This function is used to trim spaces between digits in numbers.
 *
 * The main purpose is to make children able to succeed in the exercise even if they write
 * extra spaces between digits in the answer.
 * @param {string} value
 * @returns {string}
 */
export const trimSpacesBetweenDigits = (value: string | number): string => {
  const stringValue = value.toString();
  // checks if char is digit, dot, space or comma.
  const searchRegExp = new RegExp(/[\d .,]/);
  if (stringValue.split('').find((char: string) => !searchRegExp.test(char))) {
    return stringValue.trim();
  }
  return stringValue.trim().split(' ').join('');
};

export const isMathLine = (value: string): boolean => {
  const mathLineRegExp = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/;
  return mathLineRegExp.test(value.replaceAll('[', '').replaceAll(']', ''));
};
