import { Trans } from 'react-i18next';
import { type TFunction } from 'i18next';
import React from 'react';
import { ROLE } from 'data/enums/role';

export const MILLISECONDS = Object.freeze({
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
  WEEK: 7 * 24 * 60 * 60 * 1000,
} as const);

export const BYTES = Object.freeze({
  KILOBYTE: 1024,
  MEGABYTE: 1024 * 1024,
  GIGABYTE: 1024 * 1024 * 1024,
} as const);

export const SECONDS = Object.freeze({
  MINUTE: 60,
  HOUR: 60 * 60,
  DAY: 24 * 60 * 60,
  WEEK: 7 * 24 * 60 * 60,
} as const);

export const autocompleteLanguages = (t: TFunction): { label: string; value: Language }[] => [
  { label: t('french'), value: 'fr' },
  { label: t('dutch'), value: 'nl' },
  { label: t('english'), value: 'en' },
];

export const languages: { label: React.ReactElement; value: Language }[] = [
  { label: <Trans i18nKey="french" />, value: 'fr' },
  { label: <Trans i18nKey="dutch" />, value: 'nl' },
  { label: <Trans i18nKey="english" />, value: 'en' },
];

export const languageIds: Record<Language, string> = {
  nl: '00000000-0000-0000-0000-000000000001',
  en: '00000000-0000-0000-0000-000000000002',
  fr: '00000000-0000-0000-0000-000000000003',
};

export const SUPPORT_EMAIL = 'content@cleverkids.io';
export const SUPPORT_EMAIL_HREF = `mailto:${SUPPORT_EMAIL}`;

export const supportedLanguages = ['en', 'fr', 'nl'] as const;

export type Language = (typeof supportedLanguages)[number];

export const HTML_WHITE_SPACE = '\u00A0';
export const HTML_GREATER_THAN = '\u003E';
export const HTML_LESS_THAN = '\u003C';

export const ROLE_NAME = Object.freeze({
  [ROLE.KID]: 'kid',
  [ROLE.COACH]: 'coach',
  [ROLE.ADMIN]: 'admin',
} as const);

export const languagesWithIds = [
  { label: <Trans i18nKey="allWords" />, value: 'allWords' },
  {
    label: <Trans i18nKey="dutch" />,
    value: '00000000-0000-0000-0000-000000000001',
  },
  {
    label: <Trans i18nKey="english" />,
    value: '00000000-0000-0000-0000-000000000002',
  },
  {
    label: <Trans i18nKey="french" />,
    value: '00000000-0000-0000-0000-000000000003',
  },
];

export const supportedLanguagesWithIds = [
  {
    label: <Trans i18nKey="dutch" />,
    value: '00000000-0000-0000-0000-000000000001',
  },
  {
    label: <Trans i18nKey="english" />,
    value: '00000000-0000-0000-0000-000000000002',
  },
  {
    label: <Trans i18nKey="french" />,
    value: '00000000-0000-0000-0000-000000000003',
  },
];

export const wordStatuses = [
  { label: <Trans i18nKey="open" />, value: 'open' },
  { label: <Trans i18nKey="preview" />, value: 'preview' },
  { label: <Trans i18nKey="closed" />, value: 'closed' },
];

export const languagesShort = [
  { label: 'NL', value: 'nl' },
  { label: 'FR', value: 'fr' },
  { label: 'EN', value: 'en' },
];

export const intercomApiKey = 'vgr9nha2';

export const frequentlyMadeMistakes = {
  AUDITIVE_MISTAKE: () => ({ key: 'auditiveMistake' }),
  DOKOLA: () => ({ key: 'dokola' }),
  FORGOT_ACCENT: () => ({ key: 'forgotAccent' }),
  NO_CAPITAL_LETTER: () => ({ key: 'noCapitalLetter' }),
  VISUAL_MISTAKE: () => ({ key: 'visualMistake' }),
  FORGOTTEN_DECIMAL: () => ({ key: 'forgottenDecimalMistake' }),
  MISSING_ZEROS: () => ({ key: 'missingZerosMistake' }),
  ADDED_ZEROS: () => ({ key: 'addedZerosMistake' }),
  SWITCHED_NUMBERS: () => ({ key: 'switchedNumbersMistake' }),
  WRONG_RANKS: () => ({ key: `wrongRanksMistake` }),
  ONE_TOO_MANY: ({ question }) => {
    if (question.includes('/') || question.includes(':')) return { key: 'oneTooManyMistakeDiv' };
    return { key: 'oneTooManyMistakeMulti' };
  },
  ONE_TOO_FEW: ({ question }) => {
    if (question.includes('/') || question.includes(':')) return { key: 'oneTooFewMistakeDiv' };
    return { key: 'oneTooFewMistakeMulti' };
  },
  MISCOUNT: () => ({ key: 'miscountMistake' }),
  DYSCALCULIA: () => ({ key: 'dyscalculiaMistake' }),
  WRONG_SPELLING: () => ({ key: 'wrongSpellingMistake' }),
  WRONG_OPERATOR: ({ question }) => {
    let operator1;
    let operator2;
    if (question.includes('+')) {
      operator1 = '+';
      operator2 = '-';
    } else if (question.includes('-')) {
      operator1 = '-';
      operator2 = '+';
    } else if (question.includes('*')) {
      operator1 = '*';
      operator2 = '/';
    } else if (question.includes('/')) {
      operator1 = '/';
      operator2 = '*';
    }
    if (operator1 && operator2) {
      return { key: 'wrongOperatorMistake', params: { operator1, operator2 } };
    }
    return { key: 'wrongOperatorMistakeFallback' };
  },
};

export const fmmTypes = [
  {
    value: 'WRONG_ANSWER',
    label: <Trans i18nKey="WRONG_ANSWER" />,
  },
  {
    value: 'FORGOTTEN_DECIMAL',
    label: <Trans i18nKey="FORGOTTEN_DECIMAL" />,
  },
  {
    value: 'MISSING_ZEROS',
    label: <Trans i18nKey="MISSING_ZEROS" />,
  },
  {
    value: 'ADDED_ZEROS',
    label: <Trans i18nKey="ADDED_ZEROS" />,
  },
  {
    value: 'SWITCHED_NUMBERS',
    label: <Trans i18nKey="SWITCHED_NUMBERS" />,
  },
  {
    value: 'WRONG_RANKS',
    label: <Trans i18nKey="WRONG_RANKS" />,
  },
  {
    value: 'MISCOUNT',
    label: <Trans i18nKey="MISCOUNT" />,
  },
  {
    value: 'DYSCALCULIA',
    label: <Trans i18nKey="DYSCALCULIA" />,
  },
  {
    value: 'WRONG_OPERATOR',
    label: <Trans i18nKey="WRONG_OPERATOR" />,
  },
  {
    value: 'AUDITIVE_MISTAKE',
    label: <Trans i18nKey="AUDITIVE_MISTAKE" />,
  },
  {
    value: 'VISUAL_MISTAKE',
    label: <Trans i18nKey="VISUAL_MISTAKE" />,
  },
  {
    value: 'FORGOT_ACCENT',
    label: <Trans i18nKey="FORGOT_ACCENT" />,
  },
  {
    value: 'NO_CAPITAL_LETTER',
    label: <Trans i18nKey="NO_CAPITAL_LETTER" />,
  },
  {
    value: 'WRONG_SPELLING',
    label: <Trans i18nKey="WRONG_SPELLING" />,
  },
  {
    value: 'DOKOLA',
    label: <Trans i18nKey="DOKOLA" />,
  },
  {
    value: 'SEMANTIC_MISTAKE',
    label: <Trans i18nKey="SEMANTIC_MISTAKE" />,
  },
];

export const fmmTypesForLanguages = [
  {
    value: 'WRONG_ANSWER',
    label: <Trans i18nKey="WRONG_ANSWER" />,
  },
  {
    value: 'AUDITIVE_MISTAKE',
    label: <Trans i18nKey="AUDITIVE_MISTAKE" />,
  },
  {
    value: 'VISUAL_MISTAKE',
    label: <Trans i18nKey="VISUAL_MISTAKE" />,
  },
  {
    value: 'FORGOT_ACCENT',
    label: <Trans i18nKey="FORGOT_ACCENT" />,
  },
  {
    value: 'NO_CAPITAL_LETTER',
    label: <Trans i18nKey="NO_CAPITAL_LETTER" />,
  },
  {
    value: 'WRONG_SPELLING',
    label: <Trans i18nKey="WRONG_SPELLING" />,
  },
  {
    value: 'DOKOLA',
    label: <Trans i18nKey="DOKOLA" />,
  },
  {
    value: 'SEMANTIC_MISTAKE',
    label: <Trans i18nKey="SEMANTIC_MISTAKE" />,
  },
];

export const durationsForSimpleActions = [
  {
    value: '1800000',
    label: <Trans i18nKey="thirtyMinutes" />,
  },
  {
    value: '3600000',
    label: <Trans i18nKey="oneHour" />,
  },
  {
    value: '7200000',
    label: <Trans i18nKey="twoHours" />,
  },
  {
    value: '14400000',
    label: <Trans i18nKey="fourHours" />,
  },
  {
    value: '28800000',
    label: <Trans i18nKey="eightHours" />,
  },
  {
    value: '43200000',
    label: <Trans i18nKey="twelveHours" />,
  },
  {
    value: '86400000',
    label: <Trans i18nKey="twentyFourHours" />,
  },
];

export const durationsMap = {
  1800000: <Trans i18nKey="thirtyMinutes" />,
  3600000: <Trans i18nKey="oneHour" />,
  7200000: <Trans i18nKey="twoHours" />,
  14400000: <Trans i18nKey="fourHours" />,
  28800000: <Trans i18nKey="eightHours" />,
  43200000: <Trans i18nKey="twelveHours" />,
  86400000: <Trans i18nKey="twentyFourHours" />,
};

export const suffixes = [{ value: 'lastName_firstName', label: <Trans i18nKey="lastnameFirstname" /> }];

export const maxFileSize = 50 * 1024 * 1024;

export const roles = [
  { value: ROLE.COACH, label: 'Coach' },
  { value: ROLE.KID, label: 'Kid' },
];

const wrapper = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginRight: 6,
};

const spanStyle = (backColor) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  zIndex: 1,
  backgroundColor: backColor,
});

const label = (color, backColor) => (
  <div style={wrapper}>
    <p style={{ margin: 0 }}>{color}</p>
    <span style={spanStyle(backColor)} />
  </div>
);

export const colors = [
  { value: '', label: 'No color' },
  {
    value: '#FFFFFF',
    label: label('White', '#FFFFFF'),
  },
  {
    value: '#00FFFF',
    label: label('Aqua blue', '#00FFFF'),
  },
  {
    value: '#006400',
    label: label('Dark green', '#006400'),
  },
  {
    value: '#90EE90',
    label: label('Light green', '#90EE90'),
  },
  {
    value: '#800080',
    label: label('Purple', '#800080'),
  },
  {
    value: '#FFA500',
    label: label('Orange', '#FFA500'),
  },
  {
    value: '#FFFF00',
    label: label('Yellow', '#FFFF00'),
  },
  {
    value: '#EC407A',
    label: label('Red', '#EC407A'),
  },
  {
    value: '#00BFA5',
    label: label('Green', '#00BFA5'),
  },
  {
    value: '#1565C0',
    label: label('Blue', '#1565C0'),
  },
  {
    value: '#2196F3',
    label: label('SkyBlue', '#2196F3'),
  },
];

export const fileTypes = [
  { value: 'TYPE_EMPTY_BOOK', label: 'Empty book file' },
  { value: 'TYPE_SOLUTION_BOOK', label: 'Solution book file' },
  { value: 'TYPE_TABLE_OF_CONTENTS', label: 'Table of contents' },
];

export const colorsForDiagram = ['#2196F3', '#EC407A', '#00BFA5'];

export const radian = Math.PI / 180;

export const questionFileTypes = [
  { value: 'questionPicture', label: <Trans i18nKey="questionPicture" /> },
  { value: 'answerPicture', label: <Trans i18nKey="answerPicture" /> },
  { value: 'questionAudio', label: <Trans i18nKey="questionAudio" /> },
];

export const excelTemplateUrl =
  'https://storage.googleapis.com/eureka-bucket/eureka-files/21%20leerlingenlijst%20Cleverkids%20voor%20scholen%20sjabloon.xlsx';

export const videoTypes = (t) => [
  {
    value: 'instruction',
    label: t('instruction'),
  },
  {
    value: 'automation',
    label: t('automation'),
  },
  {
    value: 'inspiration',
    label: t('inspiration'),
  },
  {
    value: 'explanation',
    label: t('explanation'),
  },
  {
    value: 'learnToLearn',
    label: t('learnToLearn'),
  },
];
