import { ROLE } from 'data/enums/role';
import type { History, LocationDescriptorObject } from 'history';
import * as adminPaths from './admin';
import * as kidPaths from './kid';

export const basePath = '/';

const applyBasePathTo = (path) => `${basePath}${path}`;

export const COMMON_LOCATION_STATES = {
  BACKLINK: 'backlink',
} as const;

export type CommonLocationStates = typeof COMMON_LOCATION_STATES;

/* URLS FOR EXTERNAL SERVICES */

// The urls/paths in this block are used by the external services. Be caution when changing them
export const commonUrlParams = {
  // redirect to a path or url
  redirect: 'redirect',
  // Commonly used by the API communication controller that manages redirects. This should be a plain text error message
  error: 'error',
  // An event id will be useful for the customer support purposes. The developer should provide simple functionality to copy the id
  eventID: 'event_id',
};
// Necessary during the sign up with external providers in case they do not provide all the required information (email, name, ...)
export const completeProfilePath = applyBasePathTo('complete-profile');

export const emailVerificationPath = applyBasePathTo('email-verification');

export const invitesPath = applyBasePathTo('invites');

export const maintenancePath = applyBasePathTo('maintenance');

export const signInPaths = (() => {
  const index = `${applyBasePathTo('sign-in')}`;

  return {
    index,
    params: {
      // Needed to control the sign-in role. Primarily used for testing
      role: 'role',
    },
  };
})();

export const signUpPaths = (() => {
  const index = `${applyBasePathTo('sign-up')}`;

  return {
    index,
    params: {
      // token value
      invite: 'invite',
    },
  };
})();

export const authPaths = (() => ({
  callback: applyBasePathTo('auth/:provider/callback'),
  params: {
    // The OIDC provider's code that should be exchanged for a token
    code: 'code',
  },
}))();

export const resetCredentialsPaths = (() => {
  const index = applyBasePathTo('reset-credentials');
  return {
    index,
    params: {
      // reset token
      token: 'token',
      // type of credential to reset
      type: 'type',
    },
  };
})();

export const signOutPath = applyBasePathTo('sign-out');

export const checkoutSessionPaths = (() => {
  const index = applyBasePathTo('checkout-session');
  return {
    index,
    status: (status: string) => `${index}/${status}`,
    params: {
      groupId: 'groupId',
      error: 'error',
    },
  };
})();

/* URLS FOR EXTERNAL SERVICES END */

export const profilePath = applyBasePathTo('profile');

export const forgotPasswordPath = applyBasePathTo('forgot-password');
export const videosPath = applyBasePathTo('videos');

export const videoPath = (videoId: string) => `${videosPath}/${videoId}`;

export const childrenPath = applyBasePathTo('children');

export const childPath = (childId: string) => `${childrenPath}/${childId}`;

export const editChildPath = (childId: string) => `${childrenPath}/${childId}/edit`;

export const classesPath = applyBasePathTo('classes');

export const createClassPath = `${classesPath}/create`;

export const classPath = (classId: string) => `${classesPath}/${classId}`;

export const teamMembersPath = applyBasePathTo('team-members');
export const settingsPaths = (() => {
  const index = applyBasePathTo('settings');

  return {
    index,
    params: {
      tab: 't',
      // status of the checkout session
      status: 'status',
      // error during the checkout session creation
      error: 'error',
    },
  };
})();
export const joinPath = applyBasePathTo('join');

export const groupsPath = applyBasePathTo('groups');

export const groupPath = (groupId: string) => `${groupsPath}/${groupId}`;

export const groupClassPaths = (() => {
  const index = (groupId: string, classId: string) => `${groupPath(groupId)}/classes/${classId}`;
  const createKid = (groupId: string, classId: string) => `${index(groupId, classId)}/create-kid`;

  return {
    index,
    createKid,
    params: {
      tab: 't',
    },
  };
})();
export const groupCreateClassPath = (groupId: string) => `${groupPath(groupId)}/classes/create`;

export const createLicensePath = (groupId: string) => `${groupPath(groupId)}/licenses/create`;

export const groupCreateKidPath = (groupId: string) => `${groupPath(groupId)}/create-kid`;

export const tasksPath = (() => {
  const index = applyBasePathTo('tasks');
  const newPath = `${index}/new`;
  const task = (taskId: string) => `${index}/${taskId}`;
  const statistics = (taskId: string) => `${index}/${taskId}/statistics`;
  const edit = (taskId: string) => `${index}/${taskId}/edit`;
  const duplicate = (taskId: string) => `${index}/${taskId}/duplicate`;

  return {
    index,
    new: newPath,
    task,
    statistics,
    edit,
    duplicate,
  };
})();

// Learning cultures paths
export const learningCulturesPath = applyBasePathTo('learning-cultures');

// View
export const learningCulturesViewPath = `${learningCulturesPath}/view`;

// view: Towers
export const towersViewPaths = (() => {
  const fieldsAndDomains = (cultureId: string, gradeId: string) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/towers/fields-and-domains`;

  const domain = (cultureId: string, gradeId: string, fieldId: string, domainId: string) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/towers/fields-and-domains/${fieldId}/domains/${domainId}`;

  const tower = (cultureId: string, gradeId: string, fieldId: string, domainId: string, towerId: string) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}`;

  const towerBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    towerId: string,
    towerBoxId: string,
  ) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}/tower-boxes/${towerBoxId}`;

  const questionBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    towerId: string,
    towerBoxId: string,
    questionBoxId: string,
  ) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}/tower-boxes/${towerBoxId}/question-boxes/${questionBoxId}`;

  return {
    fieldsAndDomains,
    domain,
    tower,
    towerBox,
    questionBox,
  };
})();

// view: Learning lines
export const learningLinesViewPaths = (() => {
  const fieldsAndDomains = (cultureId: string, gradeId: string) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/learning-lines/fields-and-domains`;

  const domain = (cultureId: string, gradeId: string, fieldId: string, domainId: string) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/learning-lines/fields-and-domains/${fieldId}/domains/${domainId}`;

  const learningBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    didacticBoxId: string,
    learningBoxId: string,
  ) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/learning-lines/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}/learning-boxes/${learningBoxId}`;

  const questionBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    didacticBoxId: string,
    learningBoxId: string,
    questionBoxId: string,
  ) =>
    `${learningCulturesViewPath}/${cultureId}/grades/${gradeId}/learning-lines/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}/learning-boxes/${learningBoxId}/question-boxes/${questionBoxId}`;

  return {
    fieldsAndDomains,
    domain,
    learningBox,
    questionBox,
  };
})();

// short learning cultures paths
export const learningCulturesTowerBoxPath = (towerBoxId: string) => `${learningCulturesPath}/tower-boxes/${towerBoxId}`;
export const learningCulturesLearningBoxPath = (learningBoxId: string) =>
  `${learningCulturesPath}/learning-boxes/${learningBoxId}`;

export const learningCulturesQuestionBoxPath = (questionBoxId: string) =>
  `${learningCulturesPath}/question-boxes/${questionBoxId}`;

// Manage
export const learningCulturesManagePath = `${learningCulturesPath}/manage`;

// manage: Towers
export const towersManagePaths = (() => {
  const fieldsAndDomains = (cultureId: string) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains`;

  const domain = (cultureId: string, fieldId: string, domainId: string) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}`;

  const tower = (cultureId: string, fieldId: string, domainId: string, towerId: string) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}`;

  const createTower = (cultureId: string, fieldId: string, domainId: string) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/create`;

  const towerBox = (cultureId: string, fieldId: string, domainId: string, towerId: string, towerBoxId: string) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}/tower-boxes/${towerBoxId}`;

  const viewQuestionBox = (
    cultureId: string,
    fieldId: string,
    domainId: string,
    towerId: string,
    towerBoxId: string,
    questionBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}/tower-boxes/${towerBoxId}/question-boxes/${questionBoxId}`;

  const createQuestionBox = (
    cultureId: string,
    fieldId: string,
    domainId: string,
    towerId: string,
    towerBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}/tower-boxes/${towerBoxId}/question-boxes/create`;

  const editQuestionBox = (
    cultureId: string,
    fieldId: string,
    domainId: string,
    towerId: string,
    towerBoxId: string,
    questionBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/towers/fields-and-domains/${fieldId}/domains/${domainId}/towers/${towerId}/tower-boxes/${towerBoxId}/question-boxes/${questionBoxId}/edit`;

  return {
    fieldsAndDomains,
    domain,
    tower,
    createTower,
    towerBox,
    viewQuestionBox,
    createQuestionBox,
    editQuestionBox,
  };
})();

// manage: Learning lines
export const learningLinesManagePaths = (() => {
  const grades = (cultureId: string) => `${learningCulturesManagePath}/${cultureId}/grades`;

  const fieldsAndDomains = (cultureId: string, gradeId: string) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains`;

  const domain = (cultureId: string, gradeId: string, fieldId: string, domainId: string) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}`;

  const createDidacticBox = (cultureId: string, gradeId: string, fieldId: string, domainId: string) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/create`;

  const didacticBox = (cultureId: string, gradeId: string, fieldId: string, domainId: string, didacticBoxId: string) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}`;

  const learningBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    didacticBoxId: string,
    learningBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}/learning-boxes/${learningBoxId}`;

  const viewQuestionBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    didacticBoxId: string,
    learningBoxId: string,
    questionBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}/learning-boxes/${learningBoxId}/question-boxes/${questionBoxId}`;

  const createQuestionBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    didacticBoxId: string,
    learningBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}/learning-boxes/${learningBoxId}/question-boxes/create`;

  const editQuestionBox = (
    cultureId: string,
    gradeId: string,
    fieldId: string,
    domainId: string,
    didacticBoxId: string,
    learningBoxId: string,
    questionBoxId: string,
  ) =>
    `${learningCulturesManagePath}/${cultureId}/grades/${gradeId}/fields-and-domains/${fieldId}/domains/${domainId}/didactic-boxes/${didacticBoxId}/learning-boxes/${learningBoxId}/question-boxes/${questionBoxId}/edit`;

  return {
    grades,
    fieldsAndDomains,
    domain,
    createDidacticBox,
    didacticBox,
    learningBox,
    viewQuestionBox,
    createQuestionBox,
    editQuestionBox,
  };
})();

// Question boxes
export const questionBoxesPath = applyBasePathTo('question-boxes');
export const questionBoxPath = (questionBoxId) => `${questionBoxesPath}/${questionBoxId}`;
export const editQuestionBoxPath = (questionBoxId) => `${questionBoxesPath}/${questionBoxId}/edit`;
export const addQuestionBoxPath = `${questionBoxesPath}/add-question-box`;

// Digitools paths
export const digitoolsPath = applyBasePathTo('digitools');
export const playingCardsPath = `${digitoolsPath}/playing-cards`;
export const moneyPath = `${digitoolsPath}/money`;
export const notepadPath = `${digitoolsPath}/notepad`;
export const whiteboardPath = `${digitoolsPath}/whiteboard`;
export const checkersPath = `${digitoolsPath}/checkers`;
export const tableSquarePath = `${digitoolsPath}/table-square`;
export const fixedNumberCardsPath = `${digitoolsPath}/fixed-number-cards`;
export const numberCardsPath = `${digitoolsPath}/number-cards`;
export const plusminTablePath = `${digitoolsPath}/plusmin-table`;
export const calculatorPath = `${digitoolsPath}/calculator`;
export const measuringTapePath = `${digitoolsPath}/measuring-tape`;
export const numberStepperPath = `${digitoolsPath}/number-stepper`;
export const conversionTablePath = `${digitoolsPath}/conversion-table`;
export const wordReaderPath = `${digitoolsPath}/word-reader`;
export const lineReaderPath = `${digitoolsPath}/line-reader`;
export const clockPath = `${digitoolsPath}/clock`;
export const stopwatchPath = `${clockPath}/stopwatch`;
export const digitalClockPath = `${clockPath}/digital-clock`;
export const analogClockPath = `${clockPath}/analog-clock`;
export const timerClockPath = `${clockPath}/timer-clock`;

// FilesManagement path
export const documentsPath = applyBasePathTo('documents');

// Library
export const booksPath = applyBasePathTo('books');
export const bookPath = (bookId: string) => `${booksPath}/${bookId}`;
export const addBookPath = `${booksPath}/add-book`;
export const editBookPath = (bookId: string) => `${bookPath(bookId)}/edit-book`;

// Dashboard path
export const dashboardPath = applyBasePathTo('dashboard');

// Configurations path

export const configurationsPath = applyBasePathTo('configurations');

export const fieldsPath = applyBasePathTo('fields');
export const gradesPath = applyBasePathTo('grades');
export const towersPath = applyBasePathTo('towers');
export const towerPath = (towerId: string) => `${towersPath}/${towerId}`;

export const learningLinesPath = applyBasePathTo('learning-lines');
export const learningLinePath = (learningLineId: string) => `${learningLinesPath}/${learningLineId}`;
export const didacticBoxesPath = (learningLineId: string) => `${learningLinePath(learningLineId)}/didactic-boxes`;
export const didacticBoxPath = (learningLineId: string, didacticBoxId: string) =>
  `${didacticBoxesPath(learningLineId)}/${didacticBoxId}`;
export const learningBoxesPath = (learningLineId: string, didacticBoxId: string) =>
  `${didacticBoxPath(learningLineId, didacticBoxId)}/learning-boxes`;
export const learningBoxPath = (learningLineId: string, didacticBoxId: string, learningBoxId: string) =>
  `${learningBoxesPath(learningLineId, didacticBoxId)}/${learningBoxId}`;

export const exercisesPath = applyBasePathTo('exercises');
export const exercisePath = (towerId: string) => `${exercisesPath}/${towerId}`;
export const towerBoxesPath = (towerId: string) => `${exercisePath(towerId)}/tower-boxes`;
export const towerBoxPath = (towerId: string, towerBoxId: string) => `${towerBoxesPath(towerId)}/${towerBoxId}`;

export const baseRolePaths = {
  [ROLE.ADMIN]: adminPaths.baseAdminPath,
  [ROLE.COACH]: childrenPath,
  [ROLE.KID]: kidPaths.baseKidPath,
};

export const sharedPaths = [
  fieldsPath,
  gradesPath,
  learningLinesPath,
  learningCulturesPath,
  documentsPath,
  configurationsPath,
  dashboardPath,
  digitoolsPath,
  booksPath,
  groupsPath,
  teamMembersPath,
  childrenPath,
  classesPath,
  settingsPaths.index,
  invitesPath,
  profilePath,
  questionBoxesPath,
  exercisesPath,
  towersPath,
  tasksPath.index,
];

export const isPublicPath = (path: string) => {
  const publicPaths = [
    signInPaths.index,
    emailVerificationPath,
    authPaths.callback,
    signUpPaths.index,
    forgotPasswordPath,
    resetCredentialsPaths.index,
    videosPath,
    videoPath(''),
    checkoutSessionPaths.index,
  ];

  return publicPaths.some((publicPath) => path.includes(publicPath));
};

/**
 * Doesn't handle the cross-origin redirects
 * @param location
 * @param redirect - can be an absolute URL, absolute path or relative path (to current origin)
 *
 * @example
 * withRedirect("/home", null) -> /home
 * withRedirect("/home", "/invites") -> /invites
 * withRedirect("/home", "https://cleverkids.io/invites") -> /invites
 * withRedirect("/home", "https://example.com/invites") -> /invites
 * withRedirect("/home", "https://cleverkids.io/invites?a=b&c=d") -> /invites?a=b&c=d
 * withRedirect({ pathname: '/home', search: "foo=bar" }, "https://cleverkids.io/invites?a=b&c=d") -> /invites?foo=bar
 */
export function withRedirect(location: History.LocationDescriptor, redirect?: string | null): LocationDescriptorObject {
  if (!redirect) {
    if (typeof location === 'string') {
      return {
        pathname: location,
      };
    } else {
      return location;
    }
  }

  const url = new URL(redirect, window.location.origin);

  if (typeof location.search === 'string') {
    url.search = location.search;
  }

  return {
    pathname: url.pathname,
    search: url.search,
    hash: url.hash,
  };
}

export function applyUrlPattern(pattern: string, params: Record<string, string>): string {
  const url = Object.entries(params).reduce((acc, [key, value]) => acc.replace(`:${key}`, value), pattern);

  return url;
}

export const helpDeskLinks = {
  en: 'http://help.cleverkids.io/en/collections/2390915-english-faq',
  fr: 'http://help.cleverkids.io/en/collections/3026729-faq-francais',
  nl: 'http://help.cleverkids.io/en/collections/3025193-nederlandstalige-faq',
};

// socials
export const facebookLink = 'https://www.facebook.com/cleverkids.io/';
export const instagramLink = 'https://www.instagram.com/cleverkids.io';
export const youtubeLink = 'https://www.youtube.com/channel/UCaJhh0dz_oTbb0fHXoWxNTA/featured';
export const linkedInLink = 'https://www.linkedin.com/company/cleverkids-io/';

// policies
export const pricingPath = (lang = 'nl') => `https://www.cleverkids.io/pricing?lang=${lang}`;
export const generalTermsPath = (lang = 'nl') => `https://www.cleverkids.io/terms-and-conditions?lang=${lang}`;

// digitools explanation videos links
export const CHECKERS_EXPLANATION_VIDEO_LINK = 'https://vimeo.com/660636069/f291d37390';
export const PLAYING_CARDS_EXPLANATION_VIDEO_LINK = 'https://vimeo.com/660634665/e0225fdbd3';
export const MONEY_EXPLANATION_VIDEO_LINK = 'https://vimeo.com/660638946/413a16d710';

export const LANDING_URL = 'https://cleverkids.io';
